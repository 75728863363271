import React,{ PureComponent } from 'react';
import Gotop from 'react-go-top';
import Header from '../../components/Header/Header';
import Bottom from '../../components/Bottom/Bottom';
import DownLoad from '../../components/DownLoad/DownLoad';
import './cv.less';

class Cv extends PureComponent{
  componentDidMount(){
    document && document.documentElement ? document.documentElement.scrollTop = 0 : null;
  }
  render(){
    
    return(
      <div className="Cv">
        <Header active={0} />
        <Gotop />
        <main className="page">
        <section>
            <h1 className="f-s-18 f-w-900 m-b-20">个人信息</h1>
            <div className="boxes default m-b-20">
              <div className="box bg-gray-lightest p-t-0 p-b-0">
                <div className="row">
                  <div className="col-4 p-t-10 p-b-10">张小月 | 女 | 1993</div>
                  <div className="col-4 p-t-10 p-b-10 border">本科 | 西南科技大学 | 信息工程</div>
                  <div className="col-4 p-t-10 p-b-10 border">前端开发 | 4 年工作经验</div>
                </div>
                <div className="row">
                  <div className="col-4 p-t-10 p-b-10">期望职位：Web高级前端</div>
                  <div className="col-4 p-t-10 p-b-10 border">期望城市：成都</div>
                </div>
              </div>
              <div className="box bg-gray-lightest">
                Github：
                <a href="https://github.com/Redspitee" target="_blank">https://github.com/Redspitee</a>
              </div>
              <div className="box bg-gray-lightest">
                Gitee：
                <a href="https://gitee.com/redspite" target="_blank">https://gitee.com/redspite</a>
              </div>
            </div>
          </section>
          <section>
            <h1 className="f-s-18 f-w-900 m-b-20">联系方式</h1>
            <div className="boxes default m-b-20">
              <div className="box bg-gray-lightest p-t-0 p-b-0">
                <div className="row">
                  <div className="col-4 p-t-10 p-b-10">手机：18030842838</div>
                  <div className="col-4 p-t-10 p-b-10 border">Email：718352984@qq.com</div>
                  <div className="col-4 p-t-10 p-b-10 border">网站：www.redspite.com</div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <h1 className="f-s-18 f-w-900 m-b-20">开源项目</h1>
            <div className="boxes default m-b-20">
              <div className="box bg-gray-lightest">
                
                <a href="https://github.com/Redspitee/blog" target="_blank"> 个人博客 - 使用 React + Redux + Ts + Antd + Koa 构建的个人网站，个人简历、文章及留言版展示</a>
              </div>
              <div className="box bg-gray-lightest">
                
                <a href="https://github.com/Redspitee/react-go-top" target="_blank"> react-go-top - 基于react的回到顶部插件,发布在npm的插件包 </a>
              </div>
              <div className="box bg-gray-lightest">
                
                <a href="https://github.com/Redspitee/Cv" target="_blank"> 早期博客 - 纯静态页面,百度SEO排名较靠前</a>
              </div>
            </div>
          </section>
          
         <section>
            <h1 className="f-s-18 f-w-900 m-b-20">技能掌握</h1>
            <div className="boxes default m-b-20">
              <div className="box bg-gray-lightest">
              - 四年前端开发经验，两年项目管理经验
              </div>
              <div className="box bg-gray-lightest">
              - 实际主导开发过多平台项目（Hydrid App, PC, 小程序等），制定并规范团队协作模式
              </div>
              <div className="box bg-gray-lightest">
              - 前端技术栈擅长React, Vue, 微信小程序, Cordova 混合式开发，擅长组件开发及构建优化
              </div>
              <div className="box bg-gray-lightest">
              - 后端熟练使用Nodejs, Koa 开发，数据库熟悉MongoDB
              </div>
              <div className="box bg-gray-lightest">
              - 长期维护线上个人技术博客，能独立完成项目开发，前后端通调
              </div>
            </div>
          </section>
          <section>
            <h1 className="f-s-18 f-w-900 m-b-20">工作经验</h1>
            <div className="boxes default m-b-20">
              <div className="box bg-gray-lightest">
                成都深珀数据公司 （ 2016 年 10 月 ~ 至今 ）
              </div>
              <div className="box bg-gray-lightest">
                <p className="m-t-0"><b>资深前端工程师/项目经理</b></p>
                <p>- 负责公司日常开发工作，参与项目需求研讨，设计项目前端架构</p>
                <p>- 主导开发多个移动APP、微信小程序、后台管理系统</p>
                <p>- 目技术难点突破，承担核心功能开发</p>
              </div>
            </div>
            <div className="boxes default m-b-20">
              <div className="box bg-gray-lightest">
                成都清风竞技公司 （ 2016 年 3 月 ~ 2016 年 10 月 ）
              </div>
              <div className="box bg-gray-lightest">
                <p className="m-t-0"><b>前端开发</b> </p>
                <p>- 负责公司业务与活动静态页开发及数据交互</p>
                <p>- 移动端、PC端各浏览器兼容适配</p>
              </div>
            </div>
          </section>
          <section>
            <h1 className="f-s-18 f-w-900 m-b-20">项目经验</h1>
            <div className="boxes default m-b-20">
              <div className="box bg-gray-lightest">
              四川公安大数据 （ 2018-03 ~ 2019-10 ）
              </div>
              <div className="box bg-gray-lightest">
                <p className="m-t-0"><b>项目负责人/前端开发</b></p>
                <p>公安大数据是一款基于四川省厅数据库，为方便民警实时查询公安数据、辅助民警日常工作而开发的一款数据查询APP。</p>
                <p><b>工作内容：</b> </p>
                <p>- 参与项目需求研讨、项目架构、技术选型</p>
                <p>- 使用 Cordova + Vue + Axios + Echarts 等技术栈开发 Hydrid App</p>
                <p>- 负责快速构建高质量页面、对接处理数据、真机调试及适配、热更新、APK打包上线</p>

                <p><b>解决难点：</b></p>
                <p>- 组件开发：全局接口加密sdk组件，NFC sdk 等组件的开发及接入</p>
                <p>- 复杂逻辑处理：多层嵌套菜单树的热渲染，复杂人物关系图谱的渲染、批量新增/删除图谱元素。</p>
                <p>- 复杂数据处理：多维数组的数据去重、特定元素增删查改，全局数据维护</p>
              </div>
            </div>
            <div className="boxes default m-b-20">
              <div className="box bg-gray-lightest">
              公安部质量基础服务 （ 2019-03 ~ 至今 ）
              </div>
              <div className="box bg-gray-lightest">
                <p className="m-t-0"><b>前端开发</b> </p>
                <p>为公安部一所开发的微信小程序，为社会企业及个人用户提供一个标准学习的视频培训平台。</p>

                <p><b>功能难点：</b></p>

                <p>- 多用户角色：不同角色对应不同权限逻辑处理</p>
                <p>- 优化用户体验：懒加载以减缓渲染时常、高度组件化、模块化形成界面统一风格</p>
                <p>- 复杂业务流程：如视频培训，培训课程购物车下单、视频观看学习、考试答题/补考、考试结果通知等</p>
                <p>- 复杂逻辑处理：多文件异步上传、复杂表单验证、canvas图片分享、多级分销等</p>
                <p>- 移动端、PC端各浏览器兼容适配</p>
              </div>
            </div>
          </section>
          <section>
            <h1 className="f-s-18 f-w-900 m-b-20">自我评价</h1>
            <div className="boxes default m-b-20">
              <div className="box bg-gray-lightest">
              细节把握细致，性格外向开朗，敢于承担责任。
              </div>
            </div>
          </section>
        </main>
        <Bottom />
        <DownLoad />
      </div>
    )
  }
}
export default Cv